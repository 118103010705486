<div class="navbar-container {{ app }}" [class.is-mobile]="mobileQuery.matches">
  <mat-toolbar class="navbar">
    <button
      *ngIf="authService.dashboardType === 'chat'"
      fxHide.gt-sm="true"
      mat-icon-button
      (click)="openMenu()"
    >
      <mat-icon>menu</mat-icon>
    </button>
    <a class="navbar-brand" [routerLink]="['/']" matTooltip="Home">
      <img [src]="config.appLogo" alt="Logo" class="logo" />
    </a>

    <span class="navbar-spacer"></span>

    <button
      *ngIf="
        false &&
        authService.dashboardType === 'chat' &&
        currentUser &&
        currentUser.type <= 40
      "
      (click)="setEncryption()"
      matTooltip="Setup Encryption"
      mat-icon-button
    >
      <mat-icon class="text-color">key</mat-icon>
    </button>
    <button
      *ngIf="
        authService.dashboardType === 'chat' &&
        allowAwayMessage &&
        currentUser &&
        currentUser.type <= 40
      "
      (click)="setAwayMessage()"
      [matTooltip]="'AUTOMATIC_RESPONSE.TITLE' | translate"
      mat-icon-button
    >
      <mat-icon class="text-color" svgIcon="away_message"></mat-icon>
    </button>
    <button
      (click)="logout()"
      mat-icon-button
      [matTooltip]="'TOOLTIPS.LOGOUT' | translate"
    >
      <mat-icon>exit_to_app</mat-icon>
    </button>
  </mat-toolbar>
  <mat-sidenav-container class="navbar-sidenav-container">
    <mat-sidenav
      #snav
      [opened]="!mobileQuery.matches && !authService.calling"
      [mode]="mobileQuery.matches ? 'over' : 'side'"
      [fixedInViewport]="mobileQuery.matches"
      fixedTopGap="56"
      class="sidenav"
      *ngIf="authService.dashboardType === 'chat'"
    >
      <mat-list class="current-user">
        <mat-list-item>
          <div
            matListItemAvatar
            class="avatar"
            [style.background-image]="
              'url(' +
              (currentUser.image
                ? config.storageUrl + '/' + currentUser.image
                : 'assets/img/account.svg') +
              ')'
            "
          ></div>
          <div
            style="
              flex-direction: row;
              display: flex;
              justify-content: space-between;
              align-items: center;
            "
          >
            <div>{{ currentUser.name }}</div>
            <!-- context menu toggle -->
            <div>
              <button
                mat-icon-button
                [matMenuTriggerFor]="userMenu"
                aria-label="Toggle menu"
              >
                <mat-icon>more_vert</mat-icon>
              </button>
            </div>
          </div>
        </mat-list-item>
      </mat-list>
      <mat-menu #userMenu="matMenu" xPosition="before" yPosition="below">
        <button
          mat-menu-item
          (click)="changePassword()"
          [matTooltip]="'TOOLTIPS.CHANGE_PASSWORD' | translate"
        >
          <mat-icon>vpn_key</mat-icon>
          <span>{{ "SETTINGS.CHANGE_PASSWORD" | translate }}</span>
        </button>
      </mat-menu>
      <div class="availability">
        <mat-button-toggle-group
          class="status-toggler"
          [(ngModel)]="authService.status"
          (change)="authService.toggleStatus($event)"
          name="status"
          aria-label="User Status"
        >
          <mat-button-toggle class="button-available" value="available">
            {{ "STATUS.AVAILABLE" | translate }}
          </mat-button-toggle>
          <mat-button-toggle class="button-unavailable" value="unavailable">
            {{ "STATUS.UNAVAILABLE" | translate }}
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>

      <mat-nav-list>
        <mat-divider *ngIf="authService.showNotificationNotice"></mat-divider>
        <button
          *ngIf="authService.showNotificationNotice"
          (click)="this.requestPermission()"
          mat-button
          style="width: 100%; text-align: left"
        >
          <mat-icon class="large-icon">notifications</mat-icon>
          <span class="text">
            {{ "CHAT.ENABLE_PUSH_NOTIFICATIONS" | translate }}
          </span>
        </button>
        <mat-divider></mat-divider>
        <h2 matSubheader>
          <mat-icon class="subheader-icon">assignment</mat-icon>
          <span>{{ "CHAT.COUNSELORS" | translate }}</span>
        </h2>
        <div class="available-list">
          <ng-container *ngFor="let item of authService.online | keyvalue">
            <div
              class="item {{ item.value?.status }}"
              [matTooltip]="item.value?.name"
              matTooltipPosition="right"
              *ngIf="
                item.value?.status === 'available' &&
                ((isProduction && item.value?.name !== 'Sebastiaan Pasma') ||
                  !isProduction)
              "
            >
              <div
                class="avatar"
                [style.background-image]="
                  'url(' +
                  (item.value?.image
                    ? config.storageUrl + item.value?.image
                    : 'assets/img/account.svg') +
                  ')'
                "
              ></div>
            </div>
          </ng-container>
        </div>
        <div class="unavailable-list">
          <ng-container *ngFor="let item of authService.online | keyvalue">
            <div
              class="item {{ item.value?.status }}"
              [matTooltip]="item.value?.name"
              matTooltipPosition="right"
              *ngIf="
                item.value?.status === 'unavailable' &&
                ((isProduction && item.value?.name !== 'Sebastiaan Pasma') ||
                  !isProduction)
              "
            >
              <div
                class="avatar"
                [style.background-image]="
                  'url(' +
                  (item.value?.image
                    ? config.storageUrl + item.value?.image
                    : 'assets/img/account.svg') +
                  ')'
                "
              ></div>
            </div>
          </ng-container>
        </div>

        <!--        <mat-divider></mat-divider>-->
        <h2 matSubheader>
          <mat-icon class="subheader-icon" svgIcon="my_chats"></mat-icon>
          <span>{{ "CHAT.MY_CHATS" | translate }}</span>
        </h2>
        <h3
          matSubheader
          *ngIf="
            !authService.conversations['myChats'] ||
            objectKeys(authService.conversations['myChats']).length === 0
          "
        >
          {{ "CHAT.NO_CHATS" | translate }}
        </h3>
        <app-list-user
          *ngFor="let item of authService.conversations['myChats'] | keyvalue"
          [data]="item.value"
          [chatId]="item.value?.id"
          [name]="item.value?.['appUserName']"
          [link]="['/chat/' + item.value?.id]"
          [status]="false"
        ></app-list-user>

        <h2 matSubheader>
          <mat-icon class="subheader-icon" svgIcon="new_chats"></mat-icon>
          <span>{{ "CHAT.NEW_CHATS" | translate }}</span>
        </h2>
        <app-list-user
          *ngFor="
            let item of authService.conversations['unassigned']
              | keyvalue : asIsOrder
          "
          [data]="item.value"
          [chatId]="item.value?.id"
          [name]="item.value?.['appUserName']"
          [link]="['/chat/' + item.value?.id]"
          [status]="false"
        ></app-list-user>
        <h3
          matSubheader
          *ngIf="
            !authService.conversations['unassigned'] ||
            objectKeys(authService.conversations['unassigned']).length === 0
          "
        >
          {{ "CHAT.NO_CHATS" | translate }}
        </h3>

        <h2 matSubheader>
          <mat-icon class="subheader-icon" svgIcon="chats_pending"></mat-icon>
          <span>{{ "CHAT.PENDING" | translate }}</span>
        </h2>
        <h3
          matSubheader
          *ngIf="
            !authService.conversations['pending'] ||
            objectKeys(authService.conversations['pending']).length === 0
          "
        >
          {{ "CHAT.NO_CHATS" | translate }}
        </h3>
        <app-list-user
          *ngFor="let item of authService.conversations['pending'] | keyvalue"
          [data]="item.value"
          [chatId]="item.value?.id"
          [name]="item.value?.['appUserName']"
          [link]="['/chat/' + item.value?.id]"
          [status]="false"
        ></app-list-user>

        <div
          style="
            position: absolute;
            bottom: 0;
            width: 96%;
            text-align: left;
            background: #fff;
          "
        >
          <mat-divider></mat-divider>
          <button
            [routerLink]="['/archive']"
            mat-button
            style="width: 100%; text-align: left"
          >
            <mat-icon class="large-icon">archive</mat-icon>
            <span class="text">{{ "CHAT.SHOW_ARCHIVE" | translate }}</span>
          </button>
        </div>
      </mat-nav-list>
    </mat-sidenav>

    <!-- Main content -->
    <mat-sidenav-content class="sidenav-content" id="scroll-content-container">
      <div class="progress-bar-container">
        <mat-progress-bar
          color="accent"
          mode="indeterminate"
          *ngIf="spinnerService.visibility | async"
        >
        </mat-progress-bar>
      </div>

      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
