import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable({
  providedIn: "root",
})
export class NotificationService {
  constructor(private snackBar: MatSnackBar) {}

  public openSnackBar(message: string, time: number = 1500) {
    this.snackBar.open(message, "", {
      duration: time,
    });
  }
}
